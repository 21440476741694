import Icon from './icon.png';
import Logo from './logo.png';

export default {
  logo: Logo,
  icon: Icon,
  title: 'Actividades de voluntariado',
  name: 'Ada',
  has_hours: false,
  catalogTalents: true,
  map_hide: true,
  has_reminder: true,
  publicTalents: true,
};
